
<template>
    <!-- <SiteHeader/> -->
    <SiteNavigation/>
    <main role="main">
        <router-view />
    </main>
    <SiteFooter/>
</template>
<script>
// import SiteHeader from './SiteHeader.vue'
import SiteNavigation from './SiteNavigation.vue';
import SiteFooter from './SiteFooter.vue'
export default {
    name: 'SiteLayout',
    components: {
    // SiteHeader,
    SiteFooter,
    SiteNavigation
}
  };
</script>