import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createHead } from '@vueuse/head'
import router from '@/helpers/router'
import App from './App.vue'

import './assets/styles/main.scss'

// console.log(process.env.VUE_APP_BASE_API);

const app = createApp(App);
const head = createHead();


app.use(router);
app.use(head);
app.use(createPinia())

app.mount('#app')

