<template>
    <footer class="site-footer">
        <div class="site-footer__container">
            <div class="site-footer__copy">
                <span>&copy; {{ currentYear }} Park & Control PAC GmbH</span>
            </div>
            <ul class="site-footer__navigation">
                <li> 
                    <!-- <router-link to="/impressum">Impressum</router-link>  -->
                    <a href="https://www.park-control.de/impressum" target="_blank">Impressum</a>
                </li>
                <li>
                    <!-- <router-link to="/datenschutz">Datenschutz</router-link>  -->
                    <a href="https://www.park-control.de/datenschutz" target="_blank">Datenschutz</a>
                </li>
            </ul>
        </div>
    </footer>
</template>
<script>
    export default {
        data() {
            return {
                currentYear: new Date().getFullYear(),
            }
        }
    }
</script>
<style lang="scss" >
    .site-footer {
        // padding: rem(50px 0 15px 0);
        &__container {
            @extend %site-grid;
        }

        &__copy {
            @extend %site-column;
            // margin:rem(15px 0);
            span {
                color:var(--primary-color);
                display: block;
                font-size:rem(14px);
                font-weight: 400;
                line-height: rem(20px);
                padding:rem(15px 10px);
                text-align: center;
            }   
        }
        
        &__navigation {
            display: none;
            @extend %site-column;
        }
        @include desktop {
             padding: rem(50px 0 15px 0);
            &__copy {
                span{
                    padding:rem(5px 10px);
                    text-align: left;
                }
            }
            &__navigation {
                display: block;
                list-style: none;
                margin:0;
                text-align: right;
                li {
                    display: inline-block;
                    padding:rem(5px 10px);

                    a{
                        color:var(--link-color);
                        font-size:rem(14px);
                        font-weight: 400;
                        line-height: rem(20px);
                        
                        text-decoration: none;
                        text-transform: uppercase;
                        transition: var(--default-transition);
                        will-change: color;

                        &::after {
                            background-color: var(--link-color-hover);
                            display: block;
                            content: '';
                            height: rem(1.5px);
                            width: 0;
                            will-change: width;
                            transition: var(--default-transition);
                        }
                        &:hover {
                            color:var(--link-color-hover);
                            &::after {
                                width: 100%;
                            }
                        }
                    }
                }   
            }
        }
    }
</style>