import { defineStore } from 'pinia';
import axios from 'axios';
import api from '@/services/api';

const INITIAL_AUTH_STATE = {
  isAuth: false,
  token: null,
  isLoading: false,
  error: null,
};

axios.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const userStore = useUserStore();
      await userStore.login(originalRequest.auth);
      axios.defaults.headers.common['Authorization'] = `Bearer ${userStore.token}`;
      return axios(originalRequest);
    }
    throw error;
  }
);

export const useUserStore = defineStore({
  id: 'user',
  state: () => ({
    ...INITIAL_AUTH_STATE,
  }),
  actions: {
    async login(credentials) {
      this.isLoading = true;
      try {
        const response = await api.post('/api/Accounting/Login', credentials)

        if (response.status === 200) {
          this.isAuth = true
          this.token = response.data.token;
          axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}`;
        }

      } catch (error) {
        if (error.response) {
          if(error.response.status === 403){
            this.error = error.response.data;
           } else if (error.response.status === 415) {
            this.error = 'Das eingegebene Passwort ist nicht korrekt.';
           } else if (error.response.status === 401) {
            this.error = 'Zu viele Anmeldeversuche. Bitte versuchen Sie es später erneut.';
           }
        }
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async autoLogin(ticketNumber, hash) {
      try {
        const response = await api.post('/api/Accounting/AutoLogin', {
          ticketNumber: ticketNumber, 
          hash: hash
          }
        );

        if (response.status === 200) {
          this.isAuth = true;
          this.token = response.data.token;
          axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}`;
        }
      } catch (error) {
        console.error("Login failed:", error);
        throw error;
      }
    },
    logout() {
      this.isAuth = false;
      this.token = null;
      delete axios.defaults.headers.common['Authorization'];
      this.error = null;
      this.$reset(INITIAL_AUTH_STATE);
    },
    checkAuth() {
      return this.isAuth;
    },
  },
});
